import React from "react";
import sa from "./TopHeader.module.scss";
export const TopHeader = () => {
    return(
        <div>
            <div className={sa.top_bar}>
                <div className={sa.top_bar_head}>
                    <h4>Invoice</h4>
                    <p>Review Invoices</p>
                </div>
                <div className={sa.top_bar__input_container}>
                    <span className={sa.top_bar__input_icon}></span>
                    <input type="text" placeholder={`Find your invoice`}/>
                    <button className={sa.top_bar__input_btn}>Find</button>
                </div>
            </div>
        </div>
    )
}
