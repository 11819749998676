import React, {useState} from "react";
import s from './LogIn.module.scss'
import axios from "axios";
import config from "../../config";
import {useNavigate} from 'react-router-dom'
import Input from '../../UI/Input/Input'
import useInput from "../../hooks/useInput";
import useValidation from '../../hooks/useValidation'
import EyeImage from '../../assets/icons/EyeImage.png';
import ClosedEye from '../../assets/icons/ClosedEye.png';

export const LogIn = (props) => {
    const navigate = useNavigate()
    const {isNotEmpty, isEmail} = useValidation();

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
        isTouched:emailIsTouched,
    } = useInput(isEmail);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
        showPassFalse:showPassFalse,
        isTouched: passwordIsTouched
    } = useInput(isNotEmpty);


    let emailMessage =null;
    if(!email){
        emailMessage = "Email is required";
    }
    else if (!emailIsValid){
        emailMessage = "Invalid email";
    }

    let formIsValid = false;
    if (emailIsValid
        && passwordIsValid
    ) {
        formIsValid = true;
    }


    const values = {
        email,
        password,
    }


    let postLogin = async (values) => {
        let formData = {
            email: values.email,
            password: values.password,
            device_name: 'qqq'
        }

        try {
            let response = await axios.post(`${config.baseUrl}sanctum/token`, formData);
            // sessionStorage.setItem('token', response.data.token);
            // props.setAccessToken(sessionStorage.getItem('token'));
            // sessionStorage.setItem('role', response.data.role);
            // props.setRole(sessionStorage.getItem('role'));
            console.log(response, 'resssss')
            if(response) {
                navigate('/invoice')
            }

            // if(response.data.role ==="superadmin"){
            //     navigate('/companies/created');
            // }
            // if(response.data.role ==="candidate"){
            //     navigate('/indicators');
            // }
        } catch (error) {
            console.log(error, 'errror')


        }
    }




    const submitHandler =   event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        postLogin(values);
        resetEmail();
        resetPassword();
        showPassFalse()
    }
    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }



    return(
        <div className={s.main_container}>

            <div className={s.main_content}>
                <div className={s.main_logo}>
                    <span className={s.main_logo_box}></span>
                    Logo
                </div>
                <div className={s.main_title}>Log In</div>
                <form onSubmit={submitHandler}>
                    <Input
                        hasError = {emailHasError}
                        errorText={emailMessage}
                        label='Email'
                        input={{
                            value: email,
                            placeholder: "Email",
                            type: "email",
                            onChange: emailChangeHandler,
                            onBlur: emailBlurHandler
                        }}
                    />
                    <Input
                        hasError={passwordHasError}
                        label='Password'
                        errorText="Please enter valid password."
                        onClick={passwordShowHandler}
                        image= {passwordShow ? EyeImage : ClosedEye}
                        input={{
                            width: "100%",
                            value: password,
                            placeholder: "Password",
                            type: passwordShow ? "text" : "password",
                            onChange: passwordChangeHandler,
                            onBlur: passwordBlurHandler,
                            onKeyPress: handleKeyPress
                        }}
                    />

                    {/*<div className={s.main_input_item}>*/}
                    {/*    <label htmlFor="email">Email</label>*/}
                    {/*    <input*/}
                    {/*        type="email"*/}
                    {/*        placeholder="Email"*/}
                    {/*        className="form-control"*/}
                    {/*        id="exampleInputEmail1"*/}
                    {/*        aria-describedby="emailHelp"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className={s.main_input_item}>*/}
                    {/*    <label htmlFor="password">Password</label>*/}
                    {/*    <input*/}
                    {/*        type="password"*/}
                    {/*        placeholder="Password"*/}
                    {/*        className="form-control"*/}
                    {/*        id="exampleInputPassword"*/}
                    {/*        aria-describedby="passwordHelp"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className={s.main_forgot_container}>
                        <div className={s.main_forgot}>Forgot password?</div>
                    </div>

                    <div className={s.main_btn_container}>
                        <button className={s.btn_full} type={"submit"}>Submit</button>
                        <button className={s.btn_outline} onClick={() => {
                            navigate('/home')
                        }}>Cancel</button>
                    </div>
                </form>

            </div>
            <div className={s.main_text}>This site is protected by <span className={s.main_text_link}> reCAPTCHA</span>  and the <span className={s.main_text_link}>Google Privacy Policy</span> and <span className={s.main_text_link}>Terms of Service</span> apply.</div>

        </div>
    )
}
