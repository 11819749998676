import React from "react";
import {Outlet} from 'react-router-dom'
import {LeftBar} from "../LeftBar/LeftBar";
import {TopHeader} from "../TopHeader/TopHeader"
const Layout = () => {
    return(
        <>
            <div className='row p-0 m-0'>
                <div className='col-2 p-0'>
                    <LeftBar />
                </div>
                <div className='col-10 p-0'>
                    <TopHeader />
                    <Outlet />
                </div>
            </div>
        </>
    )
}
export default Layout
