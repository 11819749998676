import React from "react";
import s from './HomePage.module.scss'
import {useNavigate} from 'react-router-dom'

export const HomePage = () => {
    const navigate = useNavigate()
    return (
        <div className={s.main_container}>
            <div className={s.main_logo}>
                <span className={s.main_logo_box}></span>
                Logo
            </div>
            <h1 className={s.main_title}>Home page component</h1>
            <button className={s.main_btn} onClick={() => {
                navigate('/login')
            }
            }>Log In</button>
        </div>
    )
}
