import React, {useState, useEffect} from "react";
import sa from "./LeftBar.module.scss";

import home_icon from '../../assets/icons/home_icon.svg'
import invoice_icon from '../../assets/icons/invoice_icon.svg'
import call_icon from '../../assets/icons/call_icon.svg'
import settings_icon from '../../assets/icons/settings_icon.svg'
import log_out_icon from '../../assets/icons/log_out_icon.svg'
import {useNavigate, ScrollRestoration, useLocation} from "react-router-dom";
export const LeftBar = () => {

    const navigate=useNavigate();
    const location =useLocation();
    const path=location.pathname;
    console.log(path, 'jjjjj')

    const [activeItem, setActiveItem]=useState('/invoice');
    let getKey = React.useCallback(
        (location, matches) => {
            let match = matches.find((m) => (m.handle)?.scrollMode);
            if ((match?.handle)?.scrollMode === "pathname") {
                return location.pathname;
            }
            return location.key;
        },
        []
    );

    useEffect(() => {
        setActiveItem(path);
    }, [path]);

    const makeActive =(route)=>{
        setActiveItem(route)
    }
    const headerItems=[
        {
            title:"My Account",
            route:"/account"
        },
        {
            title:"Invoice",
            route:"/invoice"
        },
        {
            title:"Call center",
            route:"/call_center"
        },
        {
            title:"Settings",
            route:"/settings"
        },
        {
            title:"Log Out",
            route:"/log_out"
        }
    ]


    const renderMenu=headerItems.map((item, index)=>(
        <div className={`${sa.left_link}
          ${activeItem === item.route ? sa.active : ""}`} key={index}
             onClick={()=>{
                 makeActive(item.route);
                 navigate(item.route)
             }}>
            {item.title}
        </div>
    ))

    return (
        <>
            <div className={sa.invoice_left_bar}>
                <div className={sa.invoice_left_top}>
                    <div className={sa.top_logo}>
                        <span className={sa.top_logo_box}></span>
                        Logo
                    </div>
                    <div
                        className={`${sa.left_link} ${activeItem === "/account" ? sa.active : ""}`}
                         onClick={() => { navigate('/account'); setActiveItem("/account")
                    }}>
                        <img src={home_icon} alt="" className={`mx-2`}/>My Account</div>
                    <div className={`${sa.left_link} ${activeItem === "/invoice" ? sa.active : ""}`}
                         onClick={() => { navigate('/invoice'); setActiveItem("/invoice")
                    }}>
                        <img src={invoice_icon} alt="" className={`mx-2`}/> Invoice</div>
                </div>
                <div className='aa'>
                    <div className={`${sa.left_link} ${activeItem === "/call_center" ? sa.active : ""}`}
                         onClick={() => { navigate('/call_center'); setActiveItem("/call_center")
                         }}>
                        <img src={call_icon} alt="" className={`mx-2`}/> Call center</div>

                    <div className={`${sa.left_link} ${activeItem === "/settings" ? sa.active : ""}`}
                         onClick={() => { navigate('/settings'); setActiveItem("/settings")
                         }}>
                        <img src={settings_icon} alt="" className={`mx-2`}/> Settings</div>

                    <div className={`${sa.left_link} ${activeItem === "/log_out" ? sa.active : ""}`}
                         onClick={() => { navigate('/home'); setActiveItem("/log_out")
                         }}>
                        <img src={log_out_icon} alt="" className={`mx-2`}/> Log Out</div>

                </div>
            </div>
            {/*<ScrollRestoration getKey={getKey} />*/}
        </>

    )
}



